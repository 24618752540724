* {
    margin:0;
    padding:0;
}

@font-face {
    font-family: "GothamBold";
    src: url('/fonts/Gotham-Bold.otf');
}

@font-face {
    font-family: "GothamMedium";
    src: url('/fonts/Gotham-Medium.otf');
}

html {
    background-image: url('/images/blue-gradient.jpg');
    background-repeat: no-repeat;
    background-position: top left;
    background-size: 1920px 1080px;
    font-family: "GothamBold";
    background-color: black;
    font-size: 15pt;
    overflow:hidden;
    color:white;
    cursor:none;
}

@keyframes fadeOut {
    0%   {opacity : 1}
    100%   {opacity : 0}
}

@keyframes fadeIn {
    0%   {opacity : 0}
    15%   {opacity : 0}
    100%  {opacity : 1}
}

@keyframes fadeInFast {
    0%   {opacity : 0}
    100%  {opacity : 1}
}

.hidden-to-visible {
    animation-name: fadeIn;
    animation-duration: 3s;
}

.visible-to-hidden {
    animation-name: fadeOut;
    animation-duration: 3s;
    opacity:0;
}

.hidden-to-visible-2s {
    animation-name: fadeInFast;
    animation-duration: 2s;
}

.visible-to-hidden-2s {
    animation-name: fadeOut;
    animation-duration: 2s;
    opacity:0;
}

.hidden-to-visible-1s {
    animation-name: fadeInFast;
    animation-duration: 1s;
}

.visible-to-hidden-1s {
    animation-name: fadeOut;
    animation-duration: 1s;
    opacity:0;
}

.flexed {
    display: flex;
    align-items: center;
    height: 100%;
}

button {
    cursor: none;
    background-color: rgba(0, 0, 0, 0);
    border:0 solid black;
}

button:focus, button:hover{
    outline:none;
}

main {
    width:1920px;
    height:1080px;
    position: relative;
}

#screensaver {
    position: absolute;
    width:100%;
    height:100%;
    z-index:10;
}

video {
	width:100%;
	height:100%;
	position: absolute;
	top:0;
	left:0;
	object-fit: cover;
	z-index: -1;
}

nav.primary-nav {
    position: absolute;
    bottom:0px;
    left:50px;

    button {
        margin-top:20px;
        display: block;
    }
}

nav.secondary-nav {
    position: absolute;
    bottom:0px;
    right:50px;

    button {
        margin-top:20px;
        display: block;
    }
}

.hidden {
    opacity: 0;
}

.visible {
    opacity: 1;
}

.text-box {
    z-index:5;
    position: absolute;
    border-radius:10px;
    padding:5px;
    font-family: "GothamMedium";

    & > div {
        padding:30px;
        border-width: 3px;
        border-style: solid;
        border-radius:10px;
        color: rgba(255, 255, 255, 0.8);
        height: calc(100% - 68px);
    }

    .text-box-title {
        color: white;
        font-family: "GothamBold";
        text-transform: uppercase;
        margin-bottom: 10px;
    }
}

.overlap-img {
    position: absolute;
    top:115px;
    left:35px;
    width:1760px;
    z-index: 0;
}

.overlap-img img {
    max-width: 100%;
}

.overlap-img-here {
    position: absolute;
    top: 36px;
    left:0;
    width:1920px;
    z-index: 0;
}

.overlap-img img {
    max-width: 100%;
}

footer {
    position: absolute;
    bottom:0;
    width:1360px;
    height:100px;
    border-radius: 10px;
    left:calc(50% - 1360px/2);
    padding:00px 50px;
    box-sizing: border-box;
    text-transform: uppercase;
    font-size: 35px;
}

#home {
    .text-box {
        background: radial-gradient(farthest-corner at 0px 0px, rgb(31, 31, 31) 33%, rgb(18,5,20) 100%);

        & > div {
            border-color:white;
        }
    }
}

#labyrinth {
    .text-box {
        background: radial-gradient(farthest-corner at 0px 0px, rgb(26, 40, 35) 33%, rgb(18,5,20) 100%);

        & > div {
            border-color:#289d48;
        }
    }

    footer {
        background: linear-gradient(rgba(50, 183, 91, 0.7) 33%, rgba(14, 93, 25, 0.7) 100%);
    }
}

#building {
    .text-box {
        background: radial-gradient(farthest-corner at 0px 0px, #182b3b 33%, #12121f 100%);

        & > div {
            border-color:#1eacae;
        }
    }

    footer {
        background: linear-gradient(rgba(49, 224, 225, 0.7) 33%, rgba(10, 118, 121, 0.7) 100%);

        .flexed {
            & > :first-child {
                flex:1;
            }

            & > :last-child {
                flex:3;
                display: flex;
                justify-content: space-evenly;
            }

            button {
                position: relative;

                div {
                    position: absolute;
                    top:23px;
                    width:100%;
                    text-align: center;
                    font-size:15pt;
                    color:white;
                    text-transform: uppercase;
                    font-family: "GothamBold";
                }
            }
        }
    }
}

#tower {
    .text-box {
        background: radial-gradient(farthest-corner at 0px 0px, #392b1f 33%, #1d1015 100%);

        & > div {
            border-color:#adad35;
        }
    }

    footer {
        background: linear-gradient(rgba(189,189,63, 0.7) 33%, rgba(120, 120, 18, 0.7) 100%);
    }
}

#architecture {
    .text-box {
        background: radial-gradient(farthest-corner at 0px 0px, #3d211d 33%, #1e0d14 100%);

        & > div {
            border-color:#ba7f2c;
        }
    }

    footer {
        background: linear-gradient(rgba(205,167,59, 0.7) 33%, rgba(141, 44, 15, 0.7) 100%);

        .flexed {
            & > :first-child {
                flex:2;
            }

            & > :last-child {
                flex:3;
                display: flex;
                justify-content: space-evenly;
            }

            button {
                position: relative;

                div {
                    position: absolute;
                    top:23px;
                    width:100%;
                    text-align: center;
                    font-size:15pt;
                    color:white;
                    text-transform: uppercase;
                    font-family: "GothamBold";
                }
            }
        }
    }
}

#azimut {
    .text-box {
        background: radial-gradient(farthest-corner at 0px 0px, #88288e 33%, #301036 100%);

        & > div {
            border-color:#88288e;
        }
    }

    .sub-video-section {
        border:10px solid #934d98;
        width:935px;
        height:520px;
        top:135px;
        left:285px;
        position: absolute;
        z-index: 1;
        box-shadow: 3px 3px 5px #333;
        background-color: black;
    }

    .image-frame {
        border:5px solid #934d98;
        top:135px;
        position: absolute;
        z-index: 1;
        box-shadow: 3px 3px 5px #333;
        background-color: black;
        width:310px;
        height:245px;
        right:285px;
        overflow: hidden;

        img {
            position: absolute;

        }
    }

    .image-frame-1 {
        top:135px;
    }

    .image-frame-2 {
        top:420px;
    }

    footer {
        background: linear-gradient(rgba(136, 40, 142, 0.7) 33%, rgba(50, 18, 56, 0.9) 100%);

        .flexed {
            & > :first-child {
                flex:2;
            }

            & > :last-child {
                flex:3;
                display: flex;
                justify-content: space-evenly;
            }

            button {
                position: relative;

                div {
                    position: absolute;
                    top:23px;
                    width:95%;
                    text-align: center;
                    font-size:15pt;
                    color:white;
                    text-transform: uppercase;
                    font-family: "GothamBold";
                }
            }
        }
    }
}

#here {
    .text-box {
        & > div > div {
            text-transform: uppercase;
            font-family: "GothamBold";
            margin-bottom:10px;
        }
    }

    .here-box {
        .text-box {
            background: radial-gradient(farthest-corner at 0px 0px, #3c171d 33%, #201115 100%);

            & > div {
                border-color:#c72127;
            }
        }
    }

    .labyrinth-box {
        .text-box {
            background: radial-gradient(farthest-corner at 0px 0px, rgb(26, 40, 35) 33%, rgb(18,5,20) 100%);

            & > div {
                border-color:#289d48;
            }
        }
    }

    .building-box {
        .text-box {
            background: radial-gradient(farthest-corner at 0px 0px, #182b3b 33%, #12121f 100%);

            & > div {
                border-color:#1eacae;
            }
        }
    }

    .tower-box {
        .text-box {
            background: radial-gradient(farthest-corner at 0px 0px, #392b1f 33%, #1d1015 100%);

            & > div {
                border-color:#adad35;
            }
        }
    }

    footer {
        background: linear-gradient(rgba(214,43,52,0.7) 33%, rgba(163,12,14,0.7) 100%);

        .flexed {
            & > :first-child {
                flex:2;
            }

            & > :last-child {
                flex:3;
                display: flex;
                justify-content: space-evenly;
            }

            button {
                position: relative;

                div {
                    position: absolute;
                    top:23px;
                    width:95%;
                    text-align: center;
                    font-size:15pt;
                    color:white;
                    text-transform: uppercase;
                    font-family: "GothamBold";
                }
            }
        }
    }
}